import React from 'react';
import Showdown from 'showdown';
import Sanitizer from 'sanitize-html';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/atoms/SEO';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.section`
  width: 100%;
  max-width: 126rem;
  padding: 4rem 3rem;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-right: 30rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-right: 20rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 10rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-right: 0rem;
  }
`;

const StyledHeading = styled.h2`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.font.color.main};
  position: relative;
  margin-bottom: 2rem;
  margin-top: 4rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 3rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 3.5rem;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3.3rem;
    height: 0.3rem;
    background-color: ${({ theme }) => theme.font.color.accent};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 4.3rem;
      height: 0.35rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      width: 4.8rem;
      height: 0.4rem;
    }
  }
`;

const StyledContentWrapper = styled.article`
  color: ${({ theme }) => theme.font.color.main};

  p {
    line-height: 2.25rem;
    font-size: 1.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      line-height: 2.7rem;
      font-size: 1.8rem;
    }

    strong {
      margin: 3rem 0;
    }
    em {
      font-family: ${({ theme }) => theme.font.family.cursive};
    }
    a {
      color: ${({ theme }) => theme.font.color.accent};
      font-weight: bold;
    }
  }

  blockquote {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    p {
      font-weight: bold;
      color: ${({ theme }) => theme.font.color.main};
    }

    em {
      font-family: ${({ theme }) => theme.font.family.cursive};
      font-size: 3rem;
    }
  }

  ul {
    padding-left: 2.5rem;
    margin: 2rem 0;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      padding-left: 3rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      padding-left: 3.5rem;
    }

    li {
      line-height: 2.25rem;
      font-size: 1.5rem;
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        line-height: 2.4rem;
        font-size: 1.6rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        line-height: 2.7rem;
        font-size: 1.8rem;
      }
      em {
        font-style: normal;
        font-weight: bold;
        color: ${({ theme }) => theme.font.color.main};
        border-bottom: 2px solid ${({ theme }) => theme.font.color.main};
      }
    }
  }
`;

const query = graphql`
  {
    allDatoCmsPl {
      nodes {
        heading
        text
      }
    }
  }
`;

interface Article {
  text: string;
  heading: string;
}

interface DatoCmsData {
  allDatoCmsPl: {
    nodes: Article[];
  };
}

const PLPage = () => {
  const data: DatoCmsData = useStaticQuery(query);
  const nodes = data.allDatoCmsPl.nodes;
  const { heading, text }: Article = nodes[0];

  return (
    <>
      <SEO
        title={`${heading} - Hondentrimsalon Happy Puppy`}
        description={`${text} - Hondentrimsalon Happy Puppy`}
      />
      <StyledContainer>
        <StyledWrapper>
          {nodes.map(({ text, heading }: Article) => {
            const html = new Showdown.Converter().makeHtml(text);
            const sanitizedHTML = Sanitizer(html);

            return (
              <>
                <StyledHeading>{heading}</StyledHeading>
                <StyledContentWrapper
                  dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                ></StyledContentWrapper>
              </>
            );
          })}
        </StyledWrapper>
      </StyledContainer>
    </>
  );
};

export default PLPage;
